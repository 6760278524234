import { Check, Clear, Close, Download, Sync, Visibility } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  styled,
  useTheme,
  Snackbar
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import BasicTable from "../../../../Components/BasicTable";
import FsLightbox from "fslightbox-react";
import { httpclient } from "../../../../utils/httpClient";
import MuiAlert from "@mui/material/Alert";
//import ViewPolicyDialog from "../../price_policy/ViewPolicyDialog";
import parse from "html-react-parser";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledHeaderTitle = styled(DialogTitle)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fff",
  position: "relative",
  "& button": {
    position: "absolute",
    right: "15px",
    top: "15px",
    color: "#fff",
  },
}));

const FlexContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
  marginBottom: "10px",
  alignItems: "flex-start",
}));

const FlexInnerTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "249px",
  maxWidth: "250px",
  fontWeight: "600",
}));

const FlexContent2 = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  fontSize: "17px",
}));

const FlexInnerTitle2 = styled("div")(({ theme }) => ({
  display: "flex",
  fontWeight: "600",
  gap: "5px",
  marginRight: "5px",
}));

const BoxDiv = styled("div")(({ theme }) => ({
  textAlign: "center",
}));

const Values = styled("div")(({ theme }) => ({
  marginLeft: "10px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
}));

const ImageDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  flexWrap: "wrap",
  marginBottom: "10px",
}));

const ImageCell = styled("div")(({ theme }) => ({
  margin: "10px",
  width: "190px",
  borderRadius: "5px",
  overflow: "hidden",
  "& img": {
    width: "190px",
    height: "190px",
    objectFit: "cover",
    transition: "0.5s",
    boxShadow: theme.palette.primary.shadow,
    marginBottom: "10px",
    overflow: "hidden",
  },
  "& img:hover": {
    transform: "scale(1.1)",
  },
}));

const price_policyBox = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: "15px",
  "& h5": {
    margin: "5px",
  },
}));

const AppBarTabs = styled(AppBar)(({ theme }) => ({
  background: "#fff",
  color: theme.palette.primary.dark,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const sohColumns = [
  { id: "erpBranchID", name: "Location ID" },
  { id: "erpBranchName", name: "Location Name" },
  { id: "comittedStock", name: "Comitted Stock" },
  { id: "totalInStock", name: "Total In Stock" },
  { id: "actualSOH", name: "Available Stock" },
];

const exoColumns = [
  { id: "LOCNO", name: "Location ID" },
  { id: "LNAME", name: "Location Name" },
  { id: "comittedStock", name: "Comitted Stock" },
  { id: "totalInStock", name: "Total In Stock" },
  { id: "actualSOH", name: "Available Stock" },
];

const soldColumns = [
  { id: "erpAccountNameNetSuite", name: "Customer Name" },
  { id: "customerStockCode", name: "Customer Stock Code" },
  { id: "customerStockName", name: "Customer Stock Name" },
];

const ViewNetsuiteStockDetails = (props) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [sohValue, setSohValue] = useState(0);
  const [imageLoading, setImageLoading] = useState(false);
  const [togglerLanding, setTogglerLanding] = useState(false);
  const [togglerLifestyle, setTogglerLifestyle] = useState(false);
  const [togglerDims, setTogglerDims] = useState(false);
  const [togglerOther, setTogglerOther] = useState(false);
  const [togglerFeature, setTogglerFeature] = useState(false);

  const [openPolicyDialog, setOpenPolicyDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const [singleLoading, setSingleLoading] = useState(false);

  const [imgIndex1, setImgIndex1] = useState(0);
  const [imgIndex2, setImgIndex2] = useState(0);
  const [imgIndex3, setImgIndex3] = useState(0);
  const [imgIndex4, setImgIndex4] = useState(0);
  const [imgIndex5, setImgIndex5] = useState(0);

  const [priceData, setPriceData] = useState([]);


  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeSoh = (event, newValue) => {
    setSohValue(newValue);
  };

  const [dialogDetails, setDialogDetails] = useState({
    open: true,
  });

  useEffect(() => {
    props.sendDetails(dialogDetails);
  }, [props, dialogDetails]);

  useEffect(() => {
    getPriceName();
  }, []);

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };

  const handleImageTogglerLanding = (index) => {
    setImgIndex1(index);
    setTogglerLanding((prev) => !prev);
  };

  const handleImageTogglerLifestyle = (index) => {
    setImgIndex2(index);
    setTogglerLifestyle((prev) => !prev);
  };

  const handleImageTogglerDims = (index) => {
    setImgIndex3(index);
    setTogglerDims((prev) => !prev);
  };

  const handleImageTogglerOther = (index) => {
    setImgIndex4(index);
    setTogglerOther((prev) => !prev);

  };
  const handleImageTogglerFeature = (index) => {
    setImgIndex5(index);
    setTogglerFeature((prev) => !prev);
  };

  // var priceList = [];
  // if (!props.singleLoading) {
  //   for (let i = 1; i <= 16; i++) {
  //     let priceVal = "props.viewDetails.price_list.sellPrice" + i;
  //     priceList.push(
  //       <Grid item xs={12} md={6}>
  //         <FlexContent>
  //           <FlexInnerTitle>
  //             <span>Selling Price {i}</span> <span> : </span>
  //           </FlexInnerTitle>
  //           <Values>
  //             {eval(priceVal) === 0 ? "-" : "$" + eval(priceVal)}
  //           </Values>
  //         </FlexContent>
  //       </Grid>
  //     );
  //   }
  // }

  // var priceList = [];
  // if (!props.singleLoading) {
  //   for (let i = 1; i <= 50; i++) {
  //     let priceVal = props.viewDetails.price_list !== null && props.viewDetails.price_list["sellPrice" + i];
  //     priceList.push(
  //       <Grid item xs={12} md={6} key={i}>
  //         <FlexContent>
  //           <FlexInnerTitle>
  //             <span>Selling Price {i}</span> <span> : </span>
  //           </FlexInnerTitle>
  //           <Values>
  //             {priceVal === 0 ? "-" : "$" + priceVal}
  //           </Values>
  //         </FlexContent>
  //       </Grid>
  //     );
  //   }
  // }

  // var priceList = [];
  // if (!props.singleLoading) {
  //   for (let i = 1; i <= 50; i++) {
  //     let priceVal = props.viewDetails.price_list !== null && props.viewDetails.price_list["sellPrice" + i];
  //     if (typeof priceVal !== 'undefined') {
  //       priceList.push(
  //         <Grid item xs={12} md={6} key={i}>
  //           <FlexContent>
  //             <FlexInnerTitle>
  //               <span>Selling Price {i}</span> <span> : </span>
  //             </FlexInnerTitle>
  //             <Values>
  //               {priceVal === 0 ? "-" : "$" + priceVal.toFixed(4)}
  //             </Values>
  //           </FlexContent>
  //         </Grid>
  //       );
  //     }
  //   }
  // }


  const getPriceName = () => {

    httpclient.get(`netsuite/v1/getPriceLevelName?displayFlag=1`).then(({ data }) => {
      if (data.success) {
        const activePriceData = data.records.data;
        setPriceData(activePriceData);
      }
    });
  };

  // var filterPrice = localStorage.getItem("priceList");
  // var priceList = [];

  // if (!filterPrice || filterPrice.trim() === "") {

  //   priceList.push(
  //     <Grid item xs={12} key="message">
  //       <div style={{ fontFamily: "Poppins" }}>Please filter the price list to display at <b>Settings - NetSuite Price Settings</b></div>
  //     </Grid>
  //   );
  // } else {

  //   var filterPriceArray = filterPrice.split(",").map(Number);

  //   for (let i = 1; i <= 50; i++) {

  //     if (filterPriceArray.includes(i)) {
  //       let priceVal =
  //         props.viewDetails.price_list &&
  //         props.viewDetails.price_list["sellPrice" + i];
  //       if (typeof priceVal !== "undefined") {
  //         let priceLevel = priceData?.find(
  //           (item) => item.priceLevelID === i
  //         );
  //         let priceLevelName = priceLevel
  //           ? priceLevel.name
  //           : `Selling Price ${i}`;
  //         priceList.push(
  //           <Grid item xs={12} md={6} key={i}>
  //             <FlexContent>
  //               <FlexInnerTitle>
  //                 <span>{priceLevelName}</span> <span> : </span>
  //               </FlexInnerTitle>
  //               <Values>
  //                 {priceVal === 0 ? "-" : "$" + parseFloat(priceVal).toFixed(4)}
  //               </Values>
  //             </FlexContent>
  //           </Grid>
  //         );
  //       }
  //     }
  //   }
  // }

  // var priceList = [];
  // if (!props.singleLoading) {
  //   for (let i = 1; i <= 50; i++) {
  //     let priceVal = props.viewDetails.price_list !== null && props.viewDetails.price_list["sellPrice" + i];
  //     if (typeof priceVal !== 'undefined') {
  //       let priceLevel = priceData?.find(item => item.priceLevelID === i);
  //       let priceLevelName = priceLevel ? priceLevel.name : `Selling Price ${i}`;
  //       priceList.push(
  //         <Grid item xs={12} md={6} key={i}>
  //           <FlexContent>
  //             <FlexInnerTitle>
  //               <span>{priceLevelName}</span> <span> : </span>
  //             </FlexInnerTitle>
  //             <Values>
  //               {priceVal === 0 ? "-" : "$" + parseFloat(priceVal).toFixed(4)}
  //             </Values>
  //           </FlexContent>
  //         </Grid>
  //       );
  //     }
  //   }
  // }

  var priceList = [];
  if (!props.singleLoading) {
    for (let i = 1; i <= 50; i++) {
      let priceVal = props.viewDetails.price_list !== null && props.viewDetails.price_list["sellPrice" + i];
      if (typeof priceVal !== 'undefined') {
        let priceLevel = priceData?.find(item => item.priceLevelID === i);
        if (priceLevel) {
          let priceLevelName = priceLevel.name;
          priceList.push(
            <Grid item xs={12} md={6} key={i}>
              <FlexContent>
                <FlexInnerTitle>
                  <span>{priceLevelName}</span> <span> : </span>
                </FlexInnerTitle>
                <Values>
                  {priceVal === 0 ? "-" : "$" + parseFloat(priceVal).toFixed(4)}
                </Values>
              </FlexContent>
            </Grid>
          );
        }
      }
    }
  }





  var boxesList = [];

  if (!props.singleLoading && props.viewDetails.stock_box !== null) {
    for (let i = 1; i <= 7; i++) {
      let boxBarCode = props.viewDetails.stock_box !== null && "props.viewDetails.stock_box.box" + i + "Barcode";
      let boxLength = props.viewDetails.stock_box !== null && "props.viewDetails.stock_box.box" + i + "Length";
      let boxWidth = props.viewDetails.stock_box !== null && "props.viewDetails.stock_box.box" + i + "Width";
      let boxHeight = props.viewDetails.stock_box !== null && "props.viewDetails.stock_box.box" + i + "Height";
      let boxWeight = props.viewDetails.stock_box !== null && "props.viewDetails.stock_box.box" + i + "Weight";

      eval(boxLength) !== 0 &&
        boxesList.push(
          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            flexDirection={"column"}
            alignItems="center"
          >
            <BoxDiv>
              <img
                src="/box.png"
                alt="box"
                style={{ width: "120px", height: "120px", objectFit: "cover" }}
              />

              <FlexContent2>
                <FlexInnerTitle2>
                  <span>L x W x H</span> <span> : </span>
                </FlexInnerTitle2>
                <div>
                  {(eval(boxLength) || "-") +
                    " x " +
                    (eval(boxWidth) || "-") +
                    " x " +
                    (eval(boxHeight) || "-")}
                  {/* {`${boxLength !== null ? eval(boxLength) : '-'} x ${boxWidth !== null ? eval(boxWidth) : '-'} x ${boxHeight !== null ? eval(boxHeight) : '-'}`} */}
                </div>
              </FlexContent2>

              <FlexContent2>
                <FlexInnerTitle2>
                  <span>Barcode</span> <span> : </span>
                </FlexInnerTitle2>
                <div>{eval(boxBarCode)}</div>
              </FlexContent2>
              <FlexContent2>
                <FlexInnerTitle2>
                  <span>Weight</span> <span> : </span>
                </FlexInnerTitle2>
                <div>{eval(boxWeight)} kg</div>
              </FlexContent2>
            </BoxDiv>
          </Grid>
        );
    }
  }

  const handleOpenPolicy = (id) => {
    setSingleLoading(true);
    setOpenPolicyDialog(true);
    httpclient.get(`current/price-policy/${id}`).then(({ data }) => {
      if (data.success) {
        setViewDetails(data.data);
        setSingleLoading(false);
      }
    });
  };

  const sendDetails = (callback) => {
    if (callback.open === false) {
      setOpenPolicyDialog(false);
      setViewDetails({});
    }
  };

  const handleDownload = () => {
    setImageLoading(true);
    httpclient.post(`new-system/netsuite/download-stock-image`, {
      companyID: 2386,
      stockCode: props.viewDetails.stockCode
    }).then(({ data }) => {
      if (data.success) {
        setOpen(true);
        setMessageState("success");
        setMessage(data.message);
        setImageLoading(false);

      } else {
        setOpen(true);
        setMessageState("error");
        setMessage(data.message || data.error);
        setImageLoading(false);
      }
    });
  };


  // console.log("props", props);

  /***
   * Display text based on the short or long description props
   * If the description text is not filled and if the company id is 16 (myDeal.au company) display "Required" text
   * Else display "-"
   * Function is used in similar way in System and Current Stock. // make it reusable
   */
  const displayText = (descriptionTexts) => {
    const textIsEmpty = descriptionTexts === null || descriptionTexts === "";
    return !textIsEmpty ? (
      parse(descriptionTexts)
    ) : props.viewDetails.customer_relation?.some((stock) => stock.erpAccountCustomerID === 2386) ? (
      <Typography sx={{ color: "red", fontWeight: "bold" }}>
        Required
      </Typography>
    ) : (
      "-"
    );
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={dialogDetails.open}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledHeaderTitle id="alert-dialog-title">
          <div>
            View Stock Details{" "}
            {"(" +
              (props.viewDetails.stockCode || "-") +
              "/" +
              (props.viewDetails.stockNameDefault || "-") +
              ")"}
          </div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </StyledHeaderTitle>
        {props.singleLoading ? (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box p={3} sx={{ width: "100%" }}>
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                  <Skeleton height={40} />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent sx={{ padding: "0" }}>
            <AppBarTabs position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Images" {...a11yProps(1)} />
                <Tab label="Prices" {...a11yProps(2)} />
                <Tab label="SOH By Location" {...a11yProps(3)} />
                <Tab label="Boxes" {...a11yProps(4)} />
                <Tab label="Outstanding Orders" {...a11yProps(5)} />
                <Tab label="Sold To" {...a11yProps(6)} />
              </Tabs>
            </AppBarTabs>

            <TabPanel value={value} index={0} dir={theme.direction}>
              <Box>
                <Grid container spacing={2}>
                  {/* Left Side */}
                  <Grid item xs={12} md={6}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock ID</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.stockID || "-"}</Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock Code</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.stockCode || "-"}</Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock Name</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.stockNameDefault || "-"}
                      </Values>
                    </FlexContent>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock Brand Name</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.brand?.brandName || "-"}</Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Bar Code</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.barcode || "-"}</Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Number of boxes</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        <span
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => setValue(4)}
                        >
                          {props.viewDetails.numberOfBoxes !== 0 ? props.viewDetails.numberOfBoxes : props.viewDetails.cartonQty}
                          <Visibility
                            color="primary"
                            fontSize="small"
                            style={{ marginLeft: "5px" }}
                          />
                        </span>
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock Group 1</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.sub_class
                          ? props.viewDetails.sub_class.groupName1
                          : "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock Group 2</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.class
                          ? props.viewDetails.class.groupName2
                          : "-"}
                      </Values>
                    </FlexContent>

                    {props.viewDetails.stockExclusiveToClient !== "N/A" ||
                      props.viewDetails.stockExclusiveToClient !== "" ? (
                      <>
                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Exclusive Start Date</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            {moment(
                              props.viewDetails.stockExclusiveStartDate
                            ).format("ddd, MMM Do YYYY, h:mm a") || "-"}
                          </Values>
                        </FlexContent>

                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Exclusive End Date</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            {moment(
                              props.viewDetails.stockExclusiveEndDate
                            ).format("ddd, MMM Do YYYY, h:mm a") || "-"}
                          </Values>
                        </FlexContent>

                        <FlexContent>
                          <FlexInnerTitle>
                            <span>Exclusive To</span> <span> : </span>
                          </FlexInnerTitle>
                          <Values>
                            {props.viewDetails.stockExclusiveToClientName || "-"}
                          </Values>
                        </FlexContent>
                      </>
                    ) : null}
                  </Grid>

                  {/* Left Side */}

                  {/* Right Side */}
                  <Grid item xs={12} md={6}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Average Cost</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {"$" + props.viewDetails.averageCost?.toFixed(2) || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Latest Cost</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {"$" + props.viewDetails.latestCost?.toFixed(2) || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Supplier Cost</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {"$" + (props.viewDetails.supplierCost === null ? "0.00" : props.viewDetails.supplierCost?.toFixed(2)) || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Weight</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>{props.viewDetails.weight + "kg" || "-"}</Values>
                    </FlexContent>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Active</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.active === "Y" ? (
                          <Check color="primary" />
                        ) : (
                          <Clear color="primary" /> || "-"
                        )}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Pending Process</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.pendingProcess === 1 ? (
                          <Check color="primary" />
                        ) : (
                          <Clear color="primary" /> || "-"
                        )}
                      </Values>
                    </FlexContent>

                    {/* <FlexContent>
                      <FlexInnerTitle>
                        <span>Exclusive Pending</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.exclusivePending === 1 ? (
                          <Check color="primary" />
                        ) : (
                          <Clear color="primary" /> || "-"
                        )}
                      </Values>
                    </FlexContent> */}

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock Port Origin</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.stockPortOrigin || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Supplier</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {props.viewDetails.supplier?.NAME || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock Created Date</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {moment(props.viewDetails.stockCreatedDate).format(
                          "ddd, MMM Do YYYY, h:mm a"
                        ) || "-"}
                      </Values>
                    </FlexContent>

                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Stock Last Modified</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {moment(props.viewDetails.lastModified).format(
                          "ddd, MMM Do YYYY, h:mm a"
                        ) || "-"}
                      </Values>
                    </FlexContent>
                  </Grid>
                  {/* Right Side */}

                  <Grid item xs={12}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Long Description</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {/* {props.viewDetails.shortDescription || "-"} */}
                        {displayText(props.viewDetails.shortDescription)}
                      </Values>
                    </FlexContent>
                  </Grid>

                  <Grid item xs={12}>
                    <FlexContent>
                      <FlexInnerTitle>
                        <span>Sales Text</span> <span> : </span>
                      </FlexInnerTitle>
                      <Values>
                        {/* {props.viewDetails.longDescription || "-"} */}
                        {displayText(props.viewDetails.longDescription)}
                      </Values>
                    </FlexContent>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>

            <TabPanel value={value} index={1} dir={theme.direction}>
              {props.viewDetails.isOnlineItem === "T" && props.viewDetails.active === "Y" && props.viewDetails.customer_relation?.some((customer) => customer.erpAccountCustomerID === 2386) &&
                (props.viewDetails.stockExclusiveToClient === null || props.viewDetails.stockExclusiveToClient === "MyDeal.Com.Au") &&
                <Box align={"right"}>
                  {imageLoading ?

                    <Button variant="contained" color="primary" disabled>  <CircularProgress
                      style={{
                        height: "25px",
                        width: "25px",
                        marginRight: "5px"
                      }} />Reprocessing Image...</Button>

                    :
                    <Button variant="contained" color="primary" onClick={handleDownload}> <Sync sx={{ marginRight: "5px" }} />Reprocess Image To MyDeal</Button>
                  }
                </Box>
              }
              <Box>
                {props.viewDetails.images?.landingImages.length > 0 && (
                  <>
                    <h3>Landing Images</h3>
                    <ImageDiv>
                      {props.viewDetails.images?.landingImages.map(
                        (imgs, index) => (
                          <ImageCell
                            onClick={() => handleImageTogglerLanding(index)}
                          >
                            <img src={imgs} alt="landing_image" />
                            <Box textAlign="center">
                              {imgs
                                .split("/")
                                .pop()
                                .replaceAll(".jpg", "")
                                .replaceAll(".png", "")}
                            </Box>
                          </ImageCell>
                        )
                      )}
                      <FsLightbox
                        toggler={togglerLanding}
                        sources={props.viewDetails.images?.landingImages}
                        sourceIndex={imgIndex1}
                        type="image"
                        types={[
                          ...new Array(
                            props.viewDetails.images?.landingImages.length
                          ).fill("image"),
                        ]}
                      />
                    </ImageDiv>
                  </>
                )}
                {props.viewDetails.images?.featuredImages?.length > 0 && (
                  <>
                    <h3>Featured Images</h3>
                    <ImageDiv>
                      {props.viewDetails.images?.featuredImages?.map(
                        (imgs, index) => (
                          <ImageCell
                            onClick={() => handleImageTogglerFeature(index)}
                          >
                            <img src={imgs} alt="feature_image" />
                            <Box textAlign="center">
                              {imgs.split("/").pop().replaceAll(".jpg", "").replaceAll(".png", "")}
                            </Box>
                          </ImageCell>
                        )
                      )}
                      <FsLightbox
                        toggler={togglerFeature}
                        sources={props.viewDetails.images?.featuredImages}
                        sourceIndex={imgIndex5}
                        type="image"
                        types={[
                          ...new Array(props.viewDetails.images?.featuredImages?.length).fill('image')
                        ]}
                      />
                    </ImageDiv>
                  </>
                )}

                {props.viewDetails.images?.lifestyleImages.length > 0 && (
                  <>
                    <h3>Lifestyle Images</h3>
                    <ImageDiv>
                      {props.viewDetails.images?.lifestyleImages.map(
                        (imgs, index) => (
                          <ImageCell
                            onClick={() => handleImageTogglerLifestyle(index)}
                          >
                            <img src={imgs} alt="lifestyle_image" />
                            <Box textAlign="center">
                              {imgs
                                .split("/")
                                .pop()
                                .replaceAll(".jpg", "")
                                .replaceAll(".png", "")}
                            </Box>
                          </ImageCell>
                        )
                      )}
                      <FsLightbox
                        toggler={togglerLifestyle}
                        sources={props.viewDetails.images?.lifestyleImages}
                        sourceIndex={imgIndex2}
                        type="image"
                        types={[
                          ...new Array(
                            props.viewDetails.images?.lifestyleImages.length
                          ).fill("image"),
                        ]}
                      />
                    </ImageDiv>
                  </>
                )}

                {props.viewDetails.images?.dimsImages.length > 0 && (
                  <>
                    <h3>Dims Images</h3>
                    <ImageDiv>
                      {props.viewDetails.images?.dimsImages.map((imgs, index) => (
                        <ImageCell
                          onClick={() => handleImageTogglerDims(index)}
                        >
                          <img src={imgs} alt="dims_image" />
                          <Box textAlign="center">
                            {imgs
                              .split("/")
                              .pop()
                              .replaceAll(".jpg", "")
                              .replaceAll(".png", "")}
                          </Box>
                        </ImageCell>
                      ))}
                      <FsLightbox
                        toggler={togglerDims}
                        sources={props.viewDetails.images?.dimsImages}
                        sourceIndex={imgIndex3}
                        type="image"
                        types={[
                          ...new Array(
                            props.viewDetails.images?.dimsImages.length
                          ).fill("image"),
                        ]}
                      />
                    </ImageDiv>
                  </>
                )}

                {props.viewDetails.images?.Otherimages.length > 0 && (
                  <>
                    <h3>Other Images</h3>
                    <ImageDiv>
                      {props.viewDetails.images?.Otherimages.map(
                        (imgs, index) => (
                          <ImageCell
                            onClick={() => handleImageTogglerOther(index)}
                          >
                            <img src={imgs} alt="landing_image" />
                            <Box textAlign="center">
                              {imgs
                                .split("/")
                                .pop()
                                .replaceAll(".jpg", "")
                                .replaceAll(".png", "")}
                            </Box>
                          </ImageCell>
                        )
                      )}
                      <FsLightbox
                        toggler={togglerOther}
                        sources={props.viewDetails.images?.Otherimages}
                        sourceIndex={imgIndex4}
                        type="image"
                        types={[
                          ...new Array(
                            props.viewDetails.images?.Otherimages.length
                          ).fill("image"),
                        ]}
                      />
                    </ImageDiv>
                  </>
                )}

                {props.viewDetails.images?.landingImages.length === 0 &&
                  // props.viewDetails.images?.featuredImages?.length === 0 &&
                  props.viewDetails.images?.lifestyleImages.length === 0 &&
                  props.viewDetails.images?.dimsImages.length === 0 &&
                  props.viewDetails.images?.Otherimages.length === 0 && (
                    <h4>Image not available</h4>
                  )}
              </Box>
            </TabPanel>

            <TabPanel value={value} index={2} dir={theme.direction}>
              <Box>
                <Grid container>
                  <Grid item xs={12} md={8}>
                    <Box p={3}>
                      <Grid container spacing={2}>
                        {props.viewDetails.price_list !== null ? priceList : <h4>No Price List Found</h4>}
                      </Grid>
                    </Box>
                  </Grid>
                  {props.viewDetails.price_policy?.length > 0 && (
                    <Grid item xs={12} md={4}>
                      <Box
                        pl={3}
                        style={{
                          borderLeft: "1px solid #999",
                          height: "100%",
                        }}
                      >
                        <h4
                          style={{
                            marginTop: "0",
                            marginBottom: "20px",
                            paddingBottom: "5px",
                            borderBottom: "2px solid #ccc",
                          }}
                        >
                          Active Price Policy
                        </h4>
                        {props.viewDetails.price_policy?.length > 0
                          ? props.viewDetails.price_policy.map(
                            (policy, index) => (
                              <price_policyBox>
                                <div
                                  style={{ position: "relative", top: "2px" }}
                                >
                                  <small>
                                    {" "}
                                    {index + 1} {". "}
                                  </small>
                                </div>
                                <div>
                                  <h5>
                                    Policy Reference :{" "}
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        color: "#3f51b5",
                                      }}
                                      onClick={() =>
                                        handleOpenPolicy(policy.erpPolicyID)
                                      }
                                    >
                                      {" "}
                                      {policy.policyReference +
                                        "[" +
                                        policy.erpPolicyID +
                                        "]" || "-"}{" "}
                                    </span>
                                  </h5>
                                  <h5>
                                    Price : {"$" + policy.price || "-"}{" "}
                                  </h5>
                                  <h5>
                                    Start Date :{" "}
                                    {moment(policy.startDate).format(
                                      "ddd, MMM Do YYYY, h:mm a"
                                    ) || "-"}
                                  </h5>
                                  <h5>
                                    End Date :{" "}
                                    {moment(policy.endDate).format(
                                      "ddd, MMM Do YYYY, h:mm a"
                                    ) || "-"}
                                  </h5>
                                  <h5>
                                    Active :{" "}
                                    {policy.policyActive === "T" ? (
                                      <Check
                                        fontSize="small"
                                        color="primary"
                                      />
                                    ) : (
                                      (
                                        <Clear
                                          fontSize="small"
                                          color="primary"
                                        />
                                      ) || "-"
                                    )}
                                  </h5>
                                </div>
                              </price_policyBox>
                            )
                          )
                          : ""}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </TabPanel>

            <TabPanel value={value} index={3} dir={theme.direction}>
              <Tabs
                value={sohValue}
                onChange={handleChangeSoh}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                {/* <Tab label="SyncCare SOH" {...a11yProps(0)} /> */}
                <Tab label="NetSuite SOH" {...a11yProps(0)} />
              </Tabs>

              {/* <TabPanel value={sohValue} index={0} dir={theme.direction}>
                <BasicTable
                  columns={sohColumns}
                  rows={[]}
                  stocks={true}
                />
              </TabPanel> */}

              <TabPanel value={sohValue} index={0} dir={theme.direction}>
                <BasicTable
                  columns={sohColumns}
                  rows={props.viewDetails.soh.length === 0 ? props.viewDetails.defaultSoh : props.viewDetails.soh}
                  stocks={true}
                />
              </TabPanel>
            </TabPanel>

            <TabPanel value={value} index={4} dir={theme.direction}>
              <Grid container>
                <Grid xs={12} md={9}>
                  <Box p={3}>
                    <Grid container spacing={2}>
                      {boxesList.length > 0 ? boxesList : <h4>No Box Found</h4>}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  {props.viewDetails.cartonInnerQty ||
                    props.viewDetails.cartonQty ? (
                    <Box
                      p={3}
                      style={{ borderLeft: "1px solid #999", height: "100%" }}
                    >
                      <h4>
                        {" "}
                        Carton Inner Quantity :{" "}
                        {props.viewDetails.cartonInnerQty || ""}{" "}
                      </h4>
                      <h4>
                        Carton Quantity : {props.viewDetails.cartonQty || ""}{" "}
                      </h4>
                    </Box>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={value} index={5} dir={theme.direction}>
              <Box p={3}>
                <h4> No Outstanding Orders Found. </h4>
              </Box>
            </TabPanel>

            <TabPanel value={value} index={6} dir={theme.direction}>
              {props.viewDetails.customer_relation?.length > 0 ? (
                <Box p={3}>
                  <Grid container spacing={2}>
                    <BasicTable
                      columns={soldColumns}
                      rows={props.viewDetails.customer_relation}
                    />
                  </Grid>
                </Box>
              ) : (
                <Box p={3}>
                  <span>
                    <h4>No Sold To details found.</h4>
                  </span>
                </Box>
              )}
            </TabPanel>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* {openPolicyDialog && (
        <ViewPolicyDialog
          singleLoading={singleLoading}
          viewDetails={viewDetails}
          sendDetails={sendDetails}
        />
      )} */}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ViewNetsuiteStockDetails;
