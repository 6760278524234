import PATH from "./PATHS";

import Login from "../Public/Login";

import HomeMain from "../Private/HomeMain";
import Sync from "../Private/Settings/Sync/Sync";
import Holiday from "../Private/Settings/Holiday";

import ModulesView from "../Private/Online/ModulesView";

import ReplenishmentHistorySOH from "../Private/Replenishment/History/ReplenishmentHistorySOH";
import ReplenishmentPipeline from "../Private/Replenishment/ReplenishmentPipeline";
import ReplenishmentStoresoh from "../Private/Replenishment/ReplenishmentStoresoh";
import ReplenishmentStoresales from "../Private/Replenishment/Storesales/ReplenishmentStoresales";
import StoreSales from "../Private/Replenishment/Storesales";
import ReplenishmentMslLevel from "../Private/Replenishment/MslLevel/ReplenishmentMslLevel";
import ReplenishmentQuickref from "../Private/Replenishment/Quickref/ReplenishmentQuickref";
import ReplenishmentSalesoverride from "../Private/Replenishment/Salesoverride/ReplenishmentSalesoverride";
import ReplenishmentStocksubstitute from "../Private/Replenishment/Stocksubstitute/ReplenishmentStocksubstitute";

import CurrentStock from "../Private/Online/Stock/CurrentStock";
import CurrentBranch from "../Private/Online/Stock/CurrentBranch";
import CurrentSupplier from "../Private/Online/Supplier/CurrentSupplier";
import CurrentStockGroups from "../Private/Online/Groups/CurrentStockGroups";
import CurrentPricePolicy from "../Private/Online/PricePolicy/CurrentPricePolicy";
import NewSystemStock from "../Private/Online/Stock/NewSystemStock";
import NewSystemBranch from "../Private/Online/Stock/NewSystemBranch";
import NewSystemSupplier from "../Private/Online/Supplier/NewSystemSupplier";
import NewSystemStockGroups from "../Private/Online/Groups/NewSystemStockGroups";
import NewSystemPricePolicy from "../Private/Online/PricePolicy/NewSystemPricepolicy";
import MainOrderPage from "../Private/Online/Orders/MainOrderPage";
import CSVExport from "../Private/Online/Orders/CSVExport";
import MainOrderPage1 from "../Private/Online/Orders/MainOrderPage1";
import ZeroPrice from "../Private/Online/Stock/Exceptions/ZeroPrice";
import TitleException from "../Private/Online/Stock/Exceptions/TitleException";
import CategoryException from "../Private/Online/Stock/Exceptions/CategoryException";
import SyncStatus from "../Private/Online/Stock/Exceptions/SyncStatus";
import ImageMissing from "../Private/Online/Stock/Exceptions/ImageMissing";
import CurrentCompanyAccount from "../Private/Online/Users/CurrentCompanyAccount";
import ResyncFromAx from "../Private/Online/Sync/ResyncFromAx";
import ShippitTesting from "../Private/Online/ShippitTesting/ShippitTesting";
import ListUsers from "../Private/Online/Members/ListUsers";
import AddUser from "../Private/Online/Members/AddUser";
import ListUserModules from "../Private/Online/Members/ListUsersModule";
import AddUserModule from "../Private/Online/Members/AddUserModule";
import AcceptedOrders from "../Private/Online/Orders/AcceptedOrders";
import RejectedOrders from "../Private/Online/Orders/RejectedOrders";
import ReplenishmentExcelFile from "../Private/Replenishment/ExcelFile/ReplenishmentExcelFile";
import OnlineTempleAndWebsterSOH from "../Private/Online/TempleAndWebster/SOH/OnlineTempleAndWebsterSOH";
import TWMainSOH from "../Private/Online/TempleAndWebster/TWMainSOH";

import ReplenishmentForecast from "../Private/Replenishment/Forecast/ReplenishmentForecast";
import ReplenishmentAllocationsAdjusted from "../Private/Replenishment/Allocations/Adjusted/ReplenishmentAllocationsAdjusted";
import ReplenishmentAllocationsStandard from "../Private/Replenishment/Allocations/Standard/ReplenishmentAllocationsStandard";

import NetsuiteCurrentStock from "../Private/Netsuite/NetsuiteStock/NetsuiteCurrentStock";
import NetsuiteCurrentSupplier from "../Private/Netsuite/NetsuiteStock/NetsuiteCurrentSuppliers";
import NetsuiteCurrentBranch from "../Private/Netsuite/NetsuiteStock/NetsuiteCurrentLocation";
import NetsuiteCurrentStockGroups from "../Private/Netsuite/NetsuiteStock/NetsuiteCurrentStockGroups";
import ReplenishmentStoreStockMaster from "../Private/Replenishment/StoreStockMaster/ReplenishmentStoreStockMaster";
import NewSystemBrand from "../Private/Online/Stock/NewSystemBrand";
import UploadShippingLabel from "../Private/Online/TempleAndWebster/SOH/UploadShippingLabel/UploadShippingLabel";
import ReplenishmentUploadOrder from "../Private/Online/TempleAndWebster/UploadOrder/ReplenishmentUploadOrder";
import MainOrderPage11 from "../Private/Netsuite/Orders/MainOrderPage";
import ReplenishmentOrderByBranch from "../Private/Online/TempleAndWebster/OrderByBranch/ReplenishmentOrderByBranch";
import Test from "../Private/Test";
import { Dashboard } from "@mui/icons-material";
import TWOrderTesting from "../Private/Online/TempleAndWebster/TWOrdersTesting/index";
import TWMainOrderByBranch from "../Private/Online/TempleAndWebster/TWMainOrderByBranch";
import NetsuiteCurrentPricePolicy from "../Private/Netsuite/NetsuitePricePolicy/CurrentPricePolicy";
import CSVExportNetSuite from "../Private/Netsuite/Orders/CSVExport";
import NetSuitePrice from "../Private/Settings/NetSuitePrice";
import OrderFile from "../Private/Online/TempleAndWebster/Visibility/OrderFile";
import ACRSOFile from "../Private/Online/TempleAndWebster/Visibility/ACRSOFile";
import TWNetSuiteContainer from "../Private/Online/TempleAndWebster/TWNetSuiteContainer";
import Marketplace from "../Private/Netsuite/Visibility/Marketplace";
import WorkflowMarketplace from "../Private/Netsuite/WorkFlow/Marketplace";
import Orders from "../Private/Online/TempleAndWebster/Visibility/Orders";
import PL from "../Private/Settings/3PL";

const loginData = localStorage.getItem("login");
const loginValue = JSON.parse(loginData);

// document.addEventListener('DOMContentLoaded', function() {
// if (window.location.href === `${window.location.origin}/login`) {
//     // Redirect to the desired URL
//     window.location.href = `${window.location.origin}/#/login`;
// }
// });

const publicRoutes = [
  { path: PATH.LOGIN, element: <Login /> },
  { path: "*", element: <Login /> },
];

const protectedRotues = [
  { path: PATH.DASHBOARD, element: <HomeMain /> },
  { path: "*", element: <HomeMain /> },

  //SETTINGS
  { path: PATH.SETTINGS_SYNC, element: <Sync /> },
  { path: PATH.SETTINGS_3PL, element: <PL /> },
  { path: PATH.SETTINGS_HOLIDAY, element: <Holiday /> },
  { path: PATH.NETSUITE_PRICE_SETTINGS, element: <NetSuitePrice /> },

  { path: PATH.MODULES_VIEW, element: <ModulesView /> },

  // REPLENISHMENT >> EXCEL FILE
  {
    path: PATH.REPLENISHMENT_EXCEL_FILE,
    element: <ReplenishmentExcelFile />,
  },
  //REPLENISHMENT >> CURRENT

  // REPLENISHMENT >> HISTORY
  {
    path: PATH.REPLENISHMENT_HISTORY_SOH,
    element: <ReplenishmentHistorySOH />,
  },
  //REPLENISHMENT >> PIPELINE
  {
    path: PATH.REPLENISHMENT_PIPELINE,
    element: <ReplenishmentPipeline />,
  },
  //REPLENISHMENT >> STORE SOH
  {
    path: PATH.REPLENISHMENT_STORESOH,
    element: <ReplenishmentStoresoh />,
  },

  //REPLENISHMENT >> STORE SALES
  {
    path: PATH.REPLENISHMENT_STORESALES,
    element: <StoreSales />,
  },

  //REPLENISHMENT >> MSL LEVEL LIST
  {
    path: PATH.REPLENISHMENT_MSLLEVEL,
    element: <ReplenishmentMslLevel />,
  },
  //REPLENISHMENT >> QUICK REF LIST
  {
    path: PATH.REPLENISHMENT_QUICKREF,
    element: <ReplenishmentQuickref />,
  },
  //REPLENISHMENT >> SALES OVERRIDE
  {
    path: PATH.REPLENISHMENT_SALESOVERRIDE,
    element: <ReplenishmentSalesoverride />,
  },
  //REPLENISHMENT >> STOCK SUBSTITUDE
  {
    path: PATH.REPLENISHMENT_STOCKSUBSTITUTE,
    element: <ReplenishmentStocksubstitute />,
  },
  //REPLENISHMENT >> STORE STOCK SALES MASTER
  {
    path: PATH.REPLENISHMENT_STORESTOCKMASTER,
    element: <ReplenishmentStoreStockMaster />,
  },
  //REPLENISHMENT >> FORECAST
  { path: PATH.REPLENISHMENT_FORECAST, element: <ReplenishmentForecast /> },

  //REPLENISHMENT >> ALLOCATIONS
  {
    path: PATH.REPLENISHMENT_ALLOCATIONS_ADJUSTED,
    element: <ReplenishmentAllocationsAdjusted />,
  },
  {
    path: PATH.REPLENISHMENT_ALLOCATIONS_STANDARD,
    element: <ReplenishmentAllocationsStandard />,
  },

  //ONLINE >> CURRENT
  { path: PATH.ONLINE_CURRENT_STOCK, element: <CurrentStock /> },
  { path: PATH.ONLINE_CURRENT_BRANCH, element: <CurrentBranch /> },
  { path: PATH.ONLINE_CURRENT_SUPPLIER, element: <CurrentSupplier /> },
  { path: PATH.ONLINE_CURRENT_STOCKGROUP, element: <CurrentStockGroups /> },
  { path: PATH.ONLINE_CURRENT_POLICY, element: <CurrentPricePolicy /> },

  //ONLINE >> NEW SYSTEM
  { path: PATH.ONLINE_NEW_STOCK, element: <NewSystemStock /> },
  { path: PATH.ONLINE_NEW_BRANCH, element: <NewSystemBranch /> },
  { path: PATH.ONLINE_NEW_BRAND, element: <NewSystemBrand /> },
  { path: PATH.ONLINE_NEW_SUPPLIER, element: <NewSystemSupplier /> },
  { path: PATH.ONLINE_NEW_STOCKGROUP, element: <NewSystemStockGroups /> },
  { path: PATH.ONLINE_NEW_PRICE, element: <NewSystemPricePolicy /> },

  //ONLINE >> ORDERS
  { path: PATH.ONLINE_ORDERS_ALL, element: <MainOrderPage /> },
  { path: PATH.ONLINE_ORDERS_EXPORT, element: <CSVExport /> },

  //ONLINE >> EXCEPTION_REPORT
  { path: PATH.ONLINE_EXCEPTION_PRICEORDER, element: <MainOrderPage1 /> },
  { path: PATH.ONLINE_EXCEPTION_ZERO, element: <ZeroPrice /> },
  { path: PATH.ONLINE_EXCEPTION_LONGDESCRIPTION, element: <TitleException /> },
  { path: PATH.ONLINE_EXCEPTION_CATEGORY, element: <CategoryException /> },
  { path: PATH.ONLINE_EXCEPTION_IMAGE, element: <ImageMissing /> },
  { path: PATH.ONLINE_EXCEPTION_SYNC, element: <SyncStatus /> },

  //ONLINE >> ACCOUNTS
  { path: PATH.ONLINE_ACCOUNT_CURRENT, element: <CurrentCompanyAccount /> },

  //ONLINE >> TEMPLE AND WEBSTER

  {
    path: PATH.ONLINE_TEMPLE_SOH,
    element: <TWMainSOH />,
  },
  {
    path: PATH.ONLINE_TEMPLE_ORDER_FILE,
    element: <OrderFile />,
  },
  {
    path: PATH.ONLINE_TEMPLE_ACR_SO_FILE,
    element: <ACRSOFile />,
  },
  {
    path: PATH.ONLINE_TEMPLE_UPLOADSHIPPINGLABEL,
    element: <UploadShippingLabel />,
  },
  {
    path: PATH.ONLINE_TEMPLE_UPLOADORDER,
    element: <ReplenishmentUploadOrder />,
  },
  {
    path: PATH.ONLINE_TEMPLE_ORDERBYBRANCH,
    element: <TWMainOrderByBranch />,
  },
  {
    path: PATH.ONLINE_TW_ORDERS_TESTING,
    element: <TWOrderTesting />,
  },
  {
    path: PATH.ONLINE_TW_NETSUITE_CONTAINER,
    element: <TWNetSuiteContainer />,
  },

  {
    path: PATH.NETSUITE_MARKETPLACE_ORDER,
    element: <Marketplace />
  },
  {
    path: PATH.NETSUITE_WORKFLOW_MARKETPLACE_ORDER,
    element: <WorkflowMarketplace />
  },
  
  {
    path: PATH.NETSUITE_VISIBILITY_TW_ORDER,
    element: <Orders />
  },

  { path: PATH.ONLINE_RESYNCSKU, element: <ResyncFromAx /> },
  { path: PATH.ONLINE_SHIPPIT, element: <ShippitTesting /> },

  //ONLINE >> USERS
  ...(loginValue && loginValue.UserLevel === 1
    ? [
        { path: PATH.ONLINE_USERS_LIST, element: <ListUsers /> },
        { path: PATH.ONLINE_USERS_ADD, element: <AddUser /> },
        { path: PATH.ONLINE_USERS_ADDBYID, element: <AddUser /> },
        { path: PATH.ONLINE_USERS_LISTMODULES, element: <ListUserModules /> },
        { path: PATH.ONLINE_USERS_ADDMODULE, element: <AddUserModule /> },
        {
          path: PATH.ONLINE_USERS_ADDMODULEBYID,
          element: <AddUserModule />,
        },
      ]
    : []),

  //NOT LISTED IN DRAWER MENUS
  { path: PATH.ORDERS_ACCEPTED, element: <AcceptedOrders /> },
  { path: PATH.REJECTED_ORDERS, element: <RejectedOrders /> },

  //NETSUITE >> CURRENT
  { path: PATH.NETSUITE_CURRENT_STOCK, element: <NetsuiteCurrentStock /> },
  { path: PATH.NETSUITE_CURRENT_BRANCH, element: <NetsuiteCurrentBranch /> },
  {
    path: PATH.NETSUITE_CURRENT_SUPPLIER,
    element: <NetsuiteCurrentSupplier />,
  },
  {
    path: PATH.NETSUITE_CURRENT_STOCKGROUP,
    element: <NetsuiteCurrentStockGroups />,
  },
  { path: PATH.NETSUITE_CURRENT_POLICY, element: <NetsuiteCurrentPricePolicy /> },

  //NETSUITE >> NEW SYSTEM
  { path: PATH.NETSUITE_NEW_STOCK, element: <ShippitTesting /> },
  { path: PATH.NETSUITE_NEW_BRANCH, element: <ShippitTesting /> },
  { path: PATH.NETSUITE_NEW_SUPPLIER, element: <ShippitTesting /> },
  { path: PATH.NETSUITE_NEW_STOCKGROUP, element: <ShippitTesting /> },
  { path: PATH.NETSUITE_NEW_PRICE, element: <ShippitTesting /> },

  //NETSUITE >> ORDERS
  { path: PATH.NETSUITE_ORDERS_ALL, element: <MainOrderPage11 /> },
  { path: PATH.NETSUITE_ORDERS_EXPORT, element: <CSVExportNetSuite /> },

  //NETSUITE >> EXCEPTION_REPORT
  { path: PATH.NETSUITE_EXCEPTION_PRICEORDER, element: <ShippitTesting /> },
  { path: PATH.NETSUITE_EXCEPTION_ZERO, element: <ShippitTesting /> },
  {
    path: PATH.NETSUITE_EXCEPTION_LONGDESCRIPTION,
    element: <ShippitTesting />,
  },
  { path: PATH.NETSUITE_EXCEPTION_CATEGORY, element: <ShippitTesting /> },
  { path: PATH.NETSUITE_EXCEPTION_IMAGE, element: <ShippitTesting /> },
  { path: PATH.NETSUITE_EXCEPTION_SYNC, element: <ShippitTesting /> },

  { path: PATH.TEST, element: <Test /> },
];

export { publicRoutes, protectedRotues };
